import "@cloudscape-design/global-styles/index.css";
import { createRoot } from "react-dom/client";
import { App } from "@/app";
import React from "react";

const appElement = document.getElementById("app");

if (appElement == null) {
    throw new Error("Unable to find #app element");
}

createRoot(appElement).render(<App />);
