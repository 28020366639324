import { useApi } from "@/context/api-provider";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { useNotifications } from "@/context/notification-provider";

const SummaryResponseSchema = z.object({
    isSubscriptionActive: z.boolean(),
    totalBytes: z.number().int(),
    usageForecastUsd: z.number(),
    usageUsd: z.number(),
    usageGigabyteHours: z.number(),
    remainingHours: z.number(),
    paymentSetupRequired: z.boolean(),
});

export type Summary = z.infer<typeof SummaryResponseSchema>;

export type BillingSummary = {
    summary?: Summary;
    isLoading: boolean;
    reloadSummary(): void;
};

export function useBillingSummary(): BillingSummary {
    const api = useApi();
    const { showMessage, hideMessage } = useNotifications();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [summary, setSummary] = useState<Summary>();

    async function reloadSummaryImpl() {
        async function loadSummary() {
            if (api === undefined) {
                return;
            }

            setIsLoading(true);

            const response = await api.get(
                "Billing/GetSummary",
                SummaryResponseSchema,
            );

            if (response instanceof Error) {
                const id = Math.random().toString();

                showMessage({
                    id,
                    type: "error",
                    content: response.message,
                    dismissible: true,
                    onDismiss: () => {
                        hideMessage(id);
                        loadSummary();
                    },
                });
            } else {
                setSummary(response);
            }

            setIsLoading(false);
        }

        loadSummary();
    }

    function effect() {
        reloadSummary();
    }

    const reloadSummary = useCallback(reloadSummaryImpl, [
        api,
        hideMessage,
        showMessage,
    ]);

    useEffect(effect, [api, reloadSummary]);

    return {
        summary,
        isLoading,
        reloadSummary,
    };
}
