import { useApi } from "@/context/api-provider";
import { useEffect, useState } from "react";
import { z } from "zod";
import { useNotifications } from "@/context/notification-provider";

const BillingUrlResponseSchema = z.object({
    url: z.string().url(),
});

export type BillingUrl = {
    value?: string;
};

export function useBillingUrl(): BillingUrl {
    const api = useApi();
    const { showMessage, hideMessage } = useNotifications();

    const [value, setValue] = useState<string>();

    function effect() {
        let refreshTimeout: number | undefined;

        async function refreshUrlLoop() {
            if (api === undefined) {
                return;
            }

            const response = await api.get(
                "Billing/GetBillingUrl",
                BillingUrlResponseSchema,
            );

            if (response instanceof Error) {
                const id = Math.random().toString();

                showMessage({
                    id,
                    type: "error",
                    content: response.message,
                    dismissible: true,
                    onDismiss: () => {
                        hideMessage(id);
                        refreshUrlLoop();
                    },
                });

                refreshTimeout = undefined;
            } else {
                setValue(response.url);

                refreshTimeout = window.setTimeout(refreshUrlLoop, 300_000);
            }
        }

        refreshUrlLoop();

        return () => {
            window.clearTimeout(refreshTimeout);
        };
    }

    useEffect(effect, [api, showMessage, hideMessage]);

    return {
        value,
    };
}
