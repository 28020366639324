import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import {
    AppLayout,
    Flashbar,
    SideNavigation,
    TopNavigation,
} from "@cloudscape-design/components";
import { HomePage } from "@/routes/home-page";
import { ButtonDropdownProps } from "@cloudscape-design/components/button-dropdown/interfaces";
import { TopNavigationProps } from "@cloudscape-design/components/top-navigation/interfaces";
import { SideNavigationProps } from "@cloudscape-design/components/side-navigation/interfaces";
import {
    NotificationProvider,
    useNotifications,
} from "@/context/notification-provider";
import { ApiProvider } from "@/context/api-provider";
import {
    createBrowserRouter,
    Outlet,
    RouterProvider,
    useLocation,
    useNavigate,
    useRouteError,
} from "react-router-dom";
import { CreateKeyPage } from "@/routes/create-key-page";
import { KeyListPage } from "@/routes/key-list-page";
import { QueueListPage } from "@/routes/queue-list-page";
import { useBillingUrl } from "@/hooks/use-billing-url";
import { FeedbackPage } from "@/routes/feedback-page";
import { SetupPaymentPage } from "@/routes/setup-payment-page";

export function App() {
    console.log("Render `App`");

    const router = createBrowserRouter([
        {
            path: "/",
            element: <AppRoot />,
            errorElement: <AppRoot />,
            children: [
                { path: "/", element: <HomePage /> },
                { path: "/create-key", element: <CreateKeyPage /> },
                { path: "/key-list", element: <KeyListPage /> },
                { path: "/queue-list", element: <QueueListPage /> },
                { path: "/feedback", element: <FeedbackPage /> },
                { path: "/setup-payment", element: <SetupPaymentPage /> },
            ],
        },
    ]);

    return (
        <NotificationProvider>
            <ApiProvider>
                <RouterProvider router={router} />
            </ApiProvider>
        </NotificationProvider>
    );
}

function AppRoot() {
    const error = useRouteError() as any;
    const navigate = useNavigate();
    const location = useLocation();
    const { showMessage, hideMessage } = useNotifications();

    function effect() {
        const errorId = "NavigationError";

        hideMessage(errorId);

        if (error !== undefined) {
            showMessage({
                id: errorId,
                type: "error",
                content: `Navigation to "${location.pathname}" error: ${error.statusText}`,
            });
        }
    }

    useEffect(effect, [
        error,
        showMessage,
        hideMessage,
        navigate,
        location.pathname,
    ]);

    return (
        <>
            <div id="head" style={{ position: "sticky", top: 0, zIndex: 1000 }}>
                <AppHead />
            </div>

            <AppLayout
                headerSelector="#head"
                stickyNotifications={true}
                toolsHide={true}
                notifications={<AppNotifications />}
                navigation={<AppNavigation />}
                content={<Outlet />}
            />
        </>
    );
}

function AppHead() {
    console.log("Render `AppHead`");

    const { logout, user } = useAuth0();

    function handleProfileItemClick(
        event: CustomEvent<ButtonDropdownProps.ItemClickDetails>,
    ) {
        event.preventDefault();

        if (event.detail.id === "logout") {
            logout();
        }
    }

    const identity: TopNavigationProps.Identity = { href: "/" };
    const utilities: TopNavigationProps.Utility[] = [];

    if (user !== undefined) {
        utilities.push({
            type: "menu-dropdown",
            text: user.email,
            iconName: "user-profile",
            items: [{ id: "logout", text: "Sign out" }],
            onItemClick: handleProfileItemClick,
        });
    } else {
        utilities.push({
            type: "button",
            text: " Loading...",
            iconName: "status-pending",
        });
    }

    return <TopNavigation identity={identity} utilities={utilities} />;
}

function AppNotifications() {
    const { messages } = useNotifications();

    return <Flashbar items={messages} />;
}

function AppNavigation() {
    const location = useLocation();
    const navigate = useNavigate();
    const billingUrl = useBillingUrl();

    const serviceManagementItems: SideNavigationProps.Item[] = [
        { type: "link", text: "Queues", href: "/queue-list" },
        { type: "link", text: "Access Keys", href: "/key-list" },
    ];

    if (billingUrl.value) {
        serviceManagementItems.push({
            type: "link",
            text: "Billing",
            href: billingUrl.value,
            external: true,
        });
    }

    const navItems: SideNavigationProps.Item[] = [
        { type: "link", text: "Homepage", href: "/" },
        { type: "divider" },

        {
            type: "section",
            text: "Service Management",
            items: serviceManagementItems,
        },

        { type: "divider" },
        {
            type: "section",
            text: "Help Center",
            items: [
                { type: "link", text: "Send feedback", href: "/feedback" },
                {
                    type: "link",
                    text: "Documentation",
                    href: "https://docs.linesqueue.com/",
                    external: true,
                },
            ],
        },
    ];

    function handleSideNavigationClick(event: CustomEvent) {
        if (event.detail.external) {
            return;
        }

        event.preventDefault();

        navigate(event.detail.href);
    }

    return (
        <SideNavigation
            items={navItems}
            header={{ text: "LinesQueue", href: "/" }}
            activeHref={location.pathname}
            onFollow={handleSideNavigationClick}
        />
    );
}
