import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { useApi } from "@/context/api-provider";

const ListQueuesResponseSchema = z.array(
    z.object({
        name: z.string(),
        lines: z.number().int(),
        bytes: z.number().int(),
        creationTime: z.string().datetime({ offset: true }),
        updateTime: z.string().datetime({ offset: true }),
        isDeleted: z.boolean(),
    }),
);

export type QueueList = z.infer<typeof ListQueuesResponseSchema>;
export type QueueListItem = z.infer<typeof ListQueuesResponseSchema.element>;

export type QueueSearch = {
    readonly isLoading: boolean;
    readonly errorText?: string;
    readonly result: QueueList;

    deleteQueues(queueNames: string[]): void;
    loadQueues(): void;
};

export function useQueueSearch(): QueueSearch {
    const api = useApi();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const [result, setResult] = useState<QueueList>([]);

    async function deleteQueuesImpl(queueNames: string[]) {
        if (api === undefined) {
            setErrorText("Application is not initialized properly");

            return;
        }

        setErrorText(undefined);
        setIsLoading(true);

        const error = await api.post(`Queues/Delete`, { queueNames });

        if (error) {
            setErrorText(error.message);
            setIsLoading(false);
        } else {
            await loadQueues();
        }
    }

    async function loadQueuesImpl() {
        setErrorText(undefined);
        setIsLoading(true);

        if (api === undefined) {
            return;
        }

        const listResult = await api.get(
            "Queues/List",
            ListQueuesResponseSchema,
        );

        if (listResult instanceof Error) {
            setErrorText(listResult.message);
        } else {
            setResult(listResult);
        }

        setIsLoading(false);
    }

    const loadQueues = useCallback(loadQueuesImpl, [api]);
    const deleteQueues = useCallback(deleteQueuesImpl, [api, loadQueues]);

    useEffect(() => {
        loadQueues();
    }, [loadQueues]);

    return {
        isLoading,
        errorText,
        result,
        deleteQueues,
        loadQueues,
    };
}
