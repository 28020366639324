import { ReactNode, useCallback } from "react";
import { useHistory } from "@/hooks/use-history";
import { Button, Form, SpaceBetween } from "@cloudscape-design/components";

interface FormContentParams {
    readonly children: ReactNode;
    readonly submitForm: () => void;
    readonly errorText?: string;
    readonly applyText: string;
    readonly cancelText?: string;
    readonly isLoading: boolean;
}

export function FormContent(props: FormContentParams) {
    const history = useHistory();
    const goBack = useCallback(() => history.back(), [history]);

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault();
                props.submitForm();
            }}
        >
            <Form
                errorText={props.errorText}
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={goBack}
                            disabled={props.isLoading}
                        >
                            {props.cancelText ?? "Cancel"}
                        </Button>
                        <Button
                            variant="primary"
                            loading={props.isLoading}
                            disabled={props.isLoading}
                        >
                            {props.applyText}
                        </Button>
                    </SpaceBetween>
                }
            >
                {props.children}
            </Form>
        </form>
    );
}
