import { useApi } from "@/context/api-provider";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";

const ListKeysResponseSchema = z.array(
    z.object({
        name: z.string(),
        tokenId: z.string(),
        creationTime: z.string().datetime({ offset: true }),
    }),
);

export type KeyList = z.infer<typeof ListKeysResponseSchema>;
export type KeyListItem = z.infer<typeof ListKeysResponseSchema.element>;

export type KeySearch = {
    readonly isLoading: boolean;
    readonly errorText?: string;
    readonly result: KeyList;

    deleteKeys(tokenIds: string[]): void;
    loadKeys(): void;
};

export function useKeySearch(): KeySearch {
    const api = useApi();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const [result, setResult] = useState<KeyList>([]);

    async function deleteKeysImpl(tokenIds: string[]) {
        if (api === undefined) {
            setErrorText("Application is not initialized properly");

            return;
        }

        setErrorText(undefined);
        setIsLoading(true);

        const error = await api.post(`Keys/Delete`, { tokenIds });

        if (error) {
            setErrorText(error.message);
            setIsLoading(false);
        } else {
            await loadKeys();
        }
    }

    async function loadKeysImpl() {
        setErrorText(undefined);
        setIsLoading(true);

        if (api === undefined) {
            return;
        }

        const listResult = await api.get("Keys/List", ListKeysResponseSchema);

        if (listResult instanceof Error) {
            setErrorText(listResult.message);
        } else {
            setResult(listResult);
        }

        setIsLoading(false);
    }

    const loadKeys = useCallback(loadKeysImpl, [api]);
    const deleteKeys = useCallback(deleteKeysImpl, [api, loadKeys]);

    useEffect(() => {
        loadKeys();
    }, [loadKeys]);

    return {
        isLoading,
        errorText,
        result,
        deleteKeys,
        loadKeys,
    };
}
