export function getTextFilterCounterText(
    itemsCount: number,
    pagesCount: number,
    pageSize: number,
) {
    const count =
        pagesCount > 1 ? `${pageSize * (pagesCount - 1)}+` : itemsCount + "";
    return count === "1" ? `1 match` : `${count} matches`;
}

export function getHeaderCounterText(
    totalCount: number,
    selectedCount: number | undefined,
) {
    return selectedCount && selectedCount > 0
        ? `(${selectedCount}/${totalCount})`
        : `(${totalCount})`;
}

export function getMatchesCountText(count?: number) {
    return count === undefined
        ? undefined
        : count === 1
        ? `1 match`
        : `${count} matches`;
}
