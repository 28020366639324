import {
    Alert,
    AttributeEditor,
    Box,
    Button,
    Checkbox,
    Container,
    ContentLayout,
    FormField,
    Header,
    Input,
    Popover,
    SpaceBetween,
    StatusIndicator,
} from "@cloudscape-design/components";
import { useCallback, useState } from "react";
import {
    AllowedOperation,
    CreateKeyRequest,
    NewKeyData,
    useKeyFactory,
} from "@/hooks/use-key-factory";
import { useNavigate } from "react-router-dom";
import { useApi } from "@/context/api-provider";
import { FormContent } from "@/helpers/form-helpers";

type FormState = CreateKeyRequest;

type FormIssues = {
    detailsError?: string;
    allowedOperationError?: string;
    allowedQueuesErrors?: { [key: number]: string };
    validationError?: string;
};

export function CreateKeyPage() {
    const keyFactory = useKeyFactory();

    const [formState, setFormState] = useState<FormState>({
        operations: [],
        resources: [""],
        name: "",
    });

    const [issues, setIssues] = useState<FormIssues>({});

    function toggleOperation(
        operation: AllowedOperation,
        checked: boolean,
    ): void {
        const { allowedOperationError: _, ...updatedIssues } = issues;

        setIssues(updatedIssues);

        setFormState({
            ...formState,

            operations: checked
                ? formState.operations.concat(operation)
                : formState.operations.filter((item) => item !== operation),
        });
    }

    function setKeyName(name: string) {
        const { detailsError: _, ...updatedIssues } = issues;

        setIssues(updatedIssues);
        setFormState({ ...formState, name });
    }

    function addNewResource() {
        setFormState({
            ...formState,
            resources: formState.resources.concat(""),
        });
    }

    function removeResourceAt(index: number) {
        let resources = [...formState.resources];
        resources.splice(index, 1);

        if (resources.length === 0) {
            resources = [""];
        }

        setFormState({
            ...formState,
            resources,
        });
    }

    function updateResourceAt(index: number, value: string) {
        if (issues.allowedQueuesErrors) {
            delete issues.allowedQueuesErrors[index];
        }

        setIssues({
            ...issues,
            allowedQueuesErrors: issues.allowedQueuesErrors,
        });

        let resources = [...formState.resources];
        resources.splice(index, 1, value);

        setFormState({
            ...formState,
            resources,
        });
    }

    async function submitForm() {
        let issues: FormIssues = {};

        if (formState.name.length === 0) {
            issues = { ...issues, detailsError: "Key name is empty." };
        }

        if (formState.operations.length === 0) {
            issues = {
                ...issues,
                allowedOperationError:
                    "At least one allowed operations should be set.",
            };
        }

        for (let i = 0; i < formState.resources.length; i++) {
            let resource = formState.resources[i];

            if (resource.length === 0) {
                if (issues.allowedQueuesErrors === undefined) {
                    issues.allowedQueuesErrors = {};
                }

                issues.allowedQueuesErrors[i] =
                    "Queue name pattern shouldn't be empty.";
            }
        }

        if (formState.resources.length === 0) {
            issues.validationError = "A least one allowed queue should be set";
        }

        setIssues(issues);

        if (Object.keys(issues).length === 0) {
            keyFactory.createKey(formState);
        }
    }

    return (
        <ContentLayout header={<FormHeader />}>
            {keyFactory.resultKey && formState.resources.length > 0 ? (
                <ResultPage
                    keyData={keyFactory.resultKey}
                    allowedOperation={formState.operations}
                    exampleQueue={formState.resources[0].replace("*", "queue")}
                />
            ) : null}

            {keyFactory.resultKey ? null : (
                <FormContent
                    isLoading={keyFactory.isLoading}
                    submitForm={submitForm}
                    applyText="Create access key"
                    errorText={issues.validationError || keyFactory.errorText}
                >
                    <Container
                        header={<Header variant="h2">Key settings</Header>}
                    >
                        <SpaceBetween size="l">
                            <KeyDetails
                                formState={formState}
                                setKeyName={setKeyName}
                                errorText={issues.detailsError}
                            />

                            <AllowedOperationsGroup
                                formState={formState}
                                toggleOperation={toggleOperation}
                                errorText={issues.allowedOperationError}
                            />

                            <AllowedQueueList
                                formState={formState}
                                removeResourceAt={removeResourceAt}
                                updateResourceAt={updateResourceAt}
                                addNewResource={addNewResource}
                                errors={issues.allowedQueuesErrors}
                            />
                        </SpaceBetween>
                    </Container>
                </FormContent>
            )}
        </ContentLayout>
    );
}

function ResultPage(props: {
    keyData: NewKeyData;
    exampleQueue: string;
    allowedOperation: AllowedOperation[];
}) {
    const api = useApi();
    const navigate = useNavigate();
    const openKeysPage = useCallback(
        () => navigate("/key-list?selected_key=" + props.keyData.id),
        [navigate, props.keyData.id],
    );

    const now = (Date.now() / 1000) | 0;

    const events = [
        `{"event": "session_start", "timestamp": ${now}, "data": { "session_id": "1d051566" } }`,
        `{"event": "input_focus", "timestamp": ${
            now + 60
        }, "data": { "session_id": "1d051566", "target_input": "email" } }`,
        `{"event": "form_submit", "timestamp": ${
            now + 120
        }, "data": { "session_id": "1d051566", "form_data": "email=b.spoonfighter@foo.bar" } }`,
        `{"event": "session_end", "timestamp": ${
            now + 300
        }, "data": { "session_id": "1d051566" } }`,
    ];

    const writeSnippet = `curl '${api?.appServiceUrl}queues/append_lines?queue_name=${
        props.exampleQueue
    }' \\
    --header "Authorization: Bearer ${props.keyData.secret}" \\
    --data-binary "@-" << EOF
${events.join("\n")}
EOF
`;

    const readSnippet = `curl '${api?.appServiceUrl}queues/read_lines?queue_name=${props.exampleQueue}' \\
    --header "Authorization: Bearer ${props.keyData.secret}"
`;

    const showExamples =
        props.allowedOperation.includes("read_lines") ||
        props.allowedOperation.includes("append_lines");

    return (
        <>
            <Container
                variant={"stacked"}
                header={
                    <Header variant="h2">
                        Key "{props.keyData.name}" successfully created
                    </Header>
                }
                footer={
                    showExamples ? null : (
                        <Box textAlign={"right"}>
                            <Button onClick={openKeysPage} variant={"primary"}>
                                Complete
                            </Button>
                        </Box>
                    )
                }
            >
                <SpaceBetween size={"l"}>
                    <Box variant={"code"}>
                        <Header
                            variant="h3"
                            description="JWT token to use in LinesQueue API calls"
                        >
                            Key secret
                        </Header>

                        <Box padding={"l"}>
                            <code>{props.keyData.secret}</code>
                        </Box>

                        <Alert type="warning">
                            Please copy this secret and securely store it as it
                            cannot be restored.
                        </Alert>
                    </Box>

                    <Box>
                        <Popover
                            size="small"
                            position="top"
                            triggerType="custom"
                            dismissButton={false}
                            content={
                                <StatusIndicator type="success">
                                    Token copied
                                </StatusIndicator>
                            }
                        >
                            <Button
                                variant={"primary"}
                                iconName="copy"
                                onClick={() =>
                                    navigator.clipboard.writeText(
                                        props.keyData.secret,
                                    )
                                }
                            >
                                Copy
                            </Button>
                        </Popover>
                    </Box>
                </SpaceBetween>
            </Container>

            {showExamples ? (
                <Container
                    variant={"stacked"}
                    footer={
                        <Box textAlign={"right"}>
                            <Button onClick={openKeysPage} variant={"primary"}>
                                Complete
                            </Button>
                        </Box>
                    }
                    header={
                        <Header
                            variant="h2"
                            description={
                                <>
                                    Use this <code>bash</code> snippets to
                                    manipulate with LinesQueue API.
                                </>
                            }
                        >
                            Examples
                        </Header>
                    }
                >
                    <SpaceBetween size={"l"}>
                        {props.allowedOperation.includes("append_lines") ? (
                            <CodeSnippet
                                title="Write data to the queue"
                                description="Send some line-separated records"
                                code={writeSnippet}
                            />
                        ) : null}

                        {props.allowedOperation.includes("read_lines") ? (
                            <CodeSnippet
                                title="Read data from the queue"
                                description="Read all queue records"
                                code={readSnippet}
                            />
                        ) : null}
                    </SpaceBetween>
                </Container>
            ) : null}
        </>
    );
}

function CodeSnippet(props: {
    title: string;
    description: string;
    code: string;
}) {
    return (
        <>
            <Box variant={"code"}>
                <Header variant="h3" description={props.description}>
                    {props.title}
                </Header>

                <Box padding={"l"}>
                    <pre style={{ overflow: "scroll" }}>{props.code}</pre>
                </Box>

                <Popover
                    size="small"
                    position="top"
                    triggerType="custom"
                    dismissButton={false}
                    content={
                        <StatusIndicator type="success">
                            The snippet copied
                        </StatusIndicator>
                    }
                >
                    <Button
                        variant={"normal"}
                        iconName="copy"
                        onClick={() =>
                            navigator.clipboard.writeText(props.code)
                        }
                    >
                        Copy
                    </Button>
                </Popover>
            </Box>
        </>
    );
}

function AllowedQueueList(props: {
    formState: FormState;
    addNewResource: () => void;
    removeResourceAt: (index: number) => void;
    updateResourceAt: (index: number, value: string) => void;
    errors?: { [key: number]: string | undefined };
}) {
    const details = (
        <>
            Use wildcard
            <strong>
                <code> * </code>
            </strong>
            to allow any queue, or use it as a variable suffix expression, like
            <strong>
                <code> prefix.* </code>
            </strong>
        </>
    );

    function renderItem(item: string, itemIndex: number) {
        return (
            <Input
                value={item}
                placeholder="app.env.2023.*"
                onChange={({ detail: { value } }) => {
                    props.updateResourceAt(itemIndex, value);
                }}
            />
        );
    }

    function renderDescription(item: string, itemIndex: number) {
        return itemIndex === props.formState.resources.length - 1
            ? details
            : null;
    }

    function renderError(item: string, itemIndex: number) {
        return props.errors ? props.errors[itemIndex] : null;
    }

    return (
        <AttributeEditor
            disableAddButton={props.formState.resources.length >= 5}
            items={props.formState.resources}
            isItemRemovable={() => props.formState.resources.length > 1}
            addButtonText="Add another queue"
            onAddButtonClick={props.addNewResource}
            removeButtonText="Remove queue"
            onRemoveButtonClick={({ detail: { itemIndex } }) =>
                props.removeResourceAt(itemIndex)
            }
            definition={[
                {
                    label: "Allowed queues",
                    errorText: renderError,
                    constraintText: renderDescription,
                    control: renderItem,
                },
            ]}
        />
    );
}

function KeyDetails(props: {
    formState: FormState;
    setKeyName: (value: string) => void;
    errorText?: string;
}) {
    return (
        <FormField
            label="Key name"
            description="Enter the name of the key to manipulate it later."
            errorText={props.errorText}
        >
            <Input
                value={props.formState.name}
                ariaRequired={true}
                placeholder="My secure key"
                onChange={({ detail: { value } }) => props.setKeyName(value)}
            />
        </FormField>
    );
}

function FormHeader() {
    return (
        <Header
            variant="h1"
            description="You should configure and create a JWT token to manipulate your queues."
        >
            Create access key
        </Header>
    );
}

function AllowedOperationsGroup(props: {
    formState: FormState;
    toggleOperation: (operation: AllowedOperation, checked: boolean) => void;
    errorText?: string;
}) {
    return (
        <FormField
            label="Allowed operations"
            description="Choose operations that will be allowed."
            errorText={props.errorText}
        >
            <Checkbox
                checked={props.formState.operations.includes("append_lines")}
                onChange={({ detail: { checked } }) =>
                    props.toggleOperation("append_lines", checked)
                }
            >
                Append Lines
            </Checkbox>
            <Checkbox
                checked={props.formState.operations.includes("read_lines")}
                onChange={({ detail: { checked } }) =>
                    props.toggleOperation("read_lines", checked)
                }
            >
                Read Lines
            </Checkbox>
            <Checkbox
                checked={props.formState.operations.includes("list_queues")}
                onChange={({ detail: { checked } }) =>
                    props.toggleOperation("list_queues", checked)
                }
            >
                List Queues
            </Checkbox>
            <Checkbox
                checked={props.formState.operations.includes("delete_queue")}
                onChange={({ detail: { checked } }) =>
                    props.toggleOperation("delete_queue", checked)
                }
            >
                Delete Queues
            </Checkbox>
        </FormField>
    );
}
