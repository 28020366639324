import { useApi } from "@/context/api-provider";
import { useCallback, useState } from "react";
import { z } from "zod";

const BillingSetupResponseSchema = z.object({
    setupSecret: z.string(),
});

type BillingSetupRequest = {
    readonly line1: string;
    readonly line2: string | null;
    readonly city: string;
    readonly state: string;
    readonly postal_code: string;
    readonly country: string;
    readonly name: string;
};

export type BillingSetup = {
    readonly errorText?: string;
    readonly secret?: string;
    readonly isLoading?: boolean;

    setup(request: BillingSetupRequest): Promise<void>;
    reset(): void;
};

export function useBillingSetup(): BillingSetup {
    const api = useApi();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const [secret, setSecret] = useState<string>();

    async function setupImpl(request: BillingSetupRequest) {
        if (api === undefined) {
            setErrorText("Application is not initialized properly");

            return;
        }

        setErrorText(undefined);
        setIsLoading(true);

        const createResult = await api.post(
            "Billing/Setup",
            request,
            BillingSetupResponseSchema,
        );

        if (createResult instanceof Error) {
            setErrorText(createResult.message);
        } else {
            setSecret(createResult.setupSecret);
        }

        setIsLoading(false);
    }

    function resetImpl() {
        setErrorText(undefined);
        setSecret(undefined);
    }

    const setup = useCallback(setupImpl, [api]);
    const reset = useCallback(resetImpl, []);

    return {
        isLoading,
        errorText,
        secret,
        setup,
        reset,
    };
}
