import React, {
    createContext,
    JSX,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useNotifications } from "@/context/notification-provider";
import { ZodSchema, ZodType } from "zod";
import { loadStripe, Stripe } from "@stripe/stripe-js";

type ApiInterface = {
    readonly appServiceUrl: string;
    readonly stripe: Promise<Stripe | null>;

    get<T>(path: string, responseSchema: ZodSchema<T>): Promise<T | Error>;
    post<TReq>(path: string, request: TReq): Promise<undefined | Error>;
    post<TReq, TRes>(
        path: string,
        request: TReq,
        responseSchema: ZodSchema<TRes>,
    ): Promise<TRes | Error>;
};

type AppConfig = {
    authDomain: string;
    authClientId: string;
    consoleApiPrefix: string;
    appServiceUrl: string;
    stripePublicKey: string;
};

const ApiContext = createContext<ApiInterface | undefined>(undefined);

export function ApiProvider(props: { children: ReactNode }) {
    return <ConfigLoader children={props.children} />;
}

function Authenticator(props: {
    children: React.ReactNode;
    appServiceUrl: string;
    consoleApiPrefix: string;
    stripePublicKey: string;
}): JSX.Element {
    console.log("Render `Authenticator`");

    const [authToken, setAuthToken] = useState<string>();
    const {
        isAuthenticated,
        isLoading,
        getAccessTokenSilently,
        loginWithRedirect,
    } = useAuth0();
    const { showMessage, hideMessage } = useNotifications();

    function effect() {
        if (isLoading) {
            return;
        }

        async function loginAndGetToken() {
            if (isAuthenticated) {
                setAuthToken(await getAccessTokenSilently());
            } else {
                await loginWithRedirect();
            }
        }

        loginAndGetToken();
    }

    function createApi(): ApiInterface {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        };

        function handleError(error: Error) {
            const id = Math.random().toString();

            showMessage({
                id,
                type: "error",
                content: "Unhandled API invocation error: " + error,
                dismissible: true,
                onDismiss: () => hideMessage(id),
            });
        }

        return {
            appServiceUrl: props.appServiceUrl,
            stripe: loadStripe(props.stripePublicKey),

            async get<T>(path: string, responseSchema: ZodType<T>) {
                try {
                    const response = await fetch(
                        `${props.consoleApiPrefix}/${path}`,
                        {
                            method: "GET",
                            headers,
                        },
                    );

                    if (response.ok) {
                        return responseSchema.parse(await response.json());
                    }

                    return new Error(
                        `Unexpected response status: ${response.status} for ${path} request`,
                    );
                } catch (error) {
                    const result =
                        error instanceof Error
                            ? error
                            : new Error(String(error));

                    handleError(result);

                    return result;
                }
            },

            async post<TReq, TRes>(
                path: string,
                request: TReq,
                responseSchema?: ZodSchema<TRes>,
            ) {
                try {
                    const response = await fetch(
                        `${props.consoleApiPrefix}/${path}`,
                        {
                            method: "POST",
                            headers,
                            body: JSON.stringify(request),
                        },
                    );

                    if (responseSchema && response.ok) {
                        return responseSchema.parse(await response.json());
                    }

                    if (!response.ok) {
                        return new Error(
                            `Unexpected response status: ${response.status} for ${path} request`,
                        );
                    }
                } catch (error) {
                    const result =
                        error instanceof Error
                            ? error
                            : new Error(String(error));

                    handleError(result);

                    return result;
                }
            },
        };
    }

    useEffect(effect, [
        getAccessTokenSilently,
        isLoading,
        isAuthenticated,
        loginWithRedirect,
    ]);

    const api = useMemo<ApiInterface>(createApi, [
        authToken,
        hideMessage,
        props.appServiceUrl,
        props.consoleApiPrefix,
        props.stripePublicKey,
        showMessage,
    ]);

    if (authToken) {
        return <ApiContext.Provider value={api} children={props.children} />;
    }

    return <>{props.children}</>;
}

function ConfigLoader(props: { children: ReactNode }) {
    console.log("Render `ConfigLoader`");

    const [config, setConfig] = useState<AppConfig>();
    const { showMessage, hideMessage } = useNotifications();

    function effect() {
        console.log("Run `effect`@`ConfigLoader`");

        async function loadConfig() {
            const errorId = "ConfigLoadingError";

            hideMessage(errorId);

            const response = await fetch("/GetConfig");

            if (response.ok) {
                const config = await response.json();

                setConfig(config);
            } else {
                showMessage({
                    id: errorId,
                    type: "error",
                    content: "Config loading error: " + response.statusText,
                    dismissible: true,
                    onDismiss: loadConfig,
                });
            }

            console.log("Complete `effect`@`ConfigLoader`");
        }

        loadConfig();
    }

    useEffect(effect, [hideMessage, showMessage]);

    if (config) {
        const authorizationParams = {
            redirect_uri: window.location.origin,
            audience: `${window.location.origin}${config.consoleApiPrefix}`,
        };

        return (
            <Auth0Provider
                domain={config.authDomain}
                clientId={config.authClientId}
                authorizationParams={authorizationParams}
            >
                <Authenticator
                    stripePublicKey={config.stripePublicKey}
                    consoleApiPrefix={config.consoleApiPrefix}
                    appServiceUrl={config.appServiceUrl}
                    children={props.children}
                />
            </Auth0Provider>
        );
    }

    return <>{props.children}</>;
}

export function useApi() {
    return useContext<ApiInterface | undefined>(ApiContext);
}
