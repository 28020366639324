import {
    Box,
    Button,
    CollectionPreferences,
    ContentLayout,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
} from "@cloudscape-design/components";
import { ReactNode, useState } from "react";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
    getHeaderCounterText,
    getMatchesCountText,
} from "@/helpers/search-helpers";
import { KeyListItem, useKeySearch } from "@/hooks/use-key-search";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const columnDefinitions = [
    {
        id: "name",
        cell: (row: KeyListItem) => row.name,
        header: "Name",
        isRowHeader: true,
        sortingField: "name",
    },
    {
        id: "creationTime",
        header: "Creation Time",
        cell: (row: KeyListItem) =>
            moment(Date.parse(row.creationTime)).calendar(),
        width: "300px",
        sortingField: "creationTime",
    },
];

function EmptyState({ title, action }: { title: string; action: ReactNode }) {
    return (
        <Box textAlign="center" color="inherit">
            <Box
                variant="strong"
                textAlign="center"
                color="inherit"
                padding={"l"}
            >
                {title}
            </Box>
            <Box padding={"l"}>{action}</Box>
        </Box>
    );
}

export function KeyListPage() {
    return (
        <ContentLayout header={<PageHeader />}>
            <KeyList />
        </ContentLayout>
    );
}

function PageHeader() {
    return (
        <Header
            variant="h1"
            description="Keys that are used to access LinesQueue API."
        >
            Access Keys
        </Header>
    );
}

function KeyList() {
    const [preferences, setPreferences] = useState({
        pageSize: 10,
    });

    const keySearch = useKeySearch();
    const navigate = useNavigate();

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps,
    } = useCollection<KeyListItem>(keySearch.result, {
        filtering: {
            empty: (
                <EmptyState
                    title="No keys"
                    action={<Button onClick={createNewKey}>Create key</Button>}
                />
            ),
            noMatch: (
                <EmptyState
                    title="No matches"
                    action={
                        <Button onClick={() => actions.setFiltering("")}>
                            Clear filter
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    });

    const { selectedItems } = collectionProps;

    function deleteSelectedKey() {
        if (selectedItems) {
            keySearch.deleteKeys(selectedItems.map((item) => item.tokenId));
        }
    }

    function createNewKey() {
        navigate("/create-key");
    }

    function refreshKeyList() {
        keySearch.loadKeys();
    }

    return (
        <Table
            {...collectionProps}
            selectionType="multi"
            loading={keySearch.isLoading}
            header={
                <Header
                    counter={getHeaderCounterText(
                        keySearch.result.length,
                        selectedItems?.length,
                    )}
                    actions={
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button
                                loading={keySearch.isLoading}
                                onClick={deleteSelectedKey}
                                disabled={selectedItems?.length === 0}
                            >
                                Delete
                            </Button>
                            <Button
                                loading={keySearch.isLoading}
                                onClick={createNewKey}
                            >
                                Create
                            </Button>
                            <Button
                                loading={keySearch.isLoading}
                                onClick={refreshKeyList}
                                iconName="refresh"
                                variant="normal"
                            />
                        </SpaceBetween>
                    }
                >
                    Found keys
                </Header>
            }
            columnDefinitions={columnDefinitions}
            items={items}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringAriaLabel="Filter keys"
                />
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={preferences}
                    onConfirm={({ detail }) =>
                        setPreferences({ pageSize: detail.pageSize ?? 10 })
                    }
                    pageSizePreference={{
                        title: "Page size",
                        options: [
                            { value: 10, label: "10 Keys" },
                            { value: 30, label: "30 Keys" },
                            { value: 50, label: "50 Keys" },
                        ],
                    }}
                />
            }
        />
    );
}
