import React, {
    createContext,
    JSX,
    useCallback,
    useContext,
    useState,
} from "react";
import { FlashbarProps } from "@cloudscape-design/components/flashbar/interfaces";

export type ShowNotification = (
    message: FlashbarProps.MessageDefinition,
) => void;
export type HideNotification = (id: string) => void;

export type NotificationContextInterface = {
    showMessage: ShowNotification;
    hideMessage: HideNotification;
    messages: ReadonlyArray<FlashbarProps.MessageDefinition>;
};

const NotificationContext = createContext<NotificationContextInterface>({
    showMessage: (_) => {},
    hideMessage: (_) => {},
    messages: [],
});

export function NotificationProvider(props: {
    children: React.ReactNode;
}): JSX.Element {
    const [messages, setMessages] = useState<FlashbarProps.MessageDefinition[]>(
        [],
    );

    function showMessageImpl(message: FlashbarProps.MessageDefinition) {
        setMessages((messages) => [message, ...messages]);
    }

    function hideMessageImpl(id: string) {
        setMessages((messages) =>
            messages.filter((message) => message.id !== id),
        );
    }

    const showMessage = useCallback(showMessageImpl, []);
    const hideMessage = useCallback(hideMessageImpl, []);

    return (
        <NotificationContext.Provider
            value={{
                showMessage,
                hideMessage,
                messages,
            }}
        >
            {props.children}
        </NotificationContext.Provider>
    );
}

export function useNotifications() {
    return useContext<NotificationContextInterface>(NotificationContext);
}
