import { useApi } from "@/context/api-provider";
import { useCallback, useState } from "react";

export type FeedbackMessage = {
    readonly isSent?: boolean;

    send(subject: string, message: string): void;
};

export function useFeedbackMessage(): FeedbackMessage {
    const api = useApi();

    const [isSent, setIsSent] = useState<boolean>();

    async function sendImpl(subject: string, message: string) {
        if (api === undefined) {
            return;
        }

        setIsSent(false);

        await api.post("Feedback/Send", {
            subject,
            message,
        });

        setIsSent(true);
    }

    const send = useCallback(sendImpl, [api]);

    return {
        isSent: isSent,
        send,
    };
}
