import {
    Container,
    ContentLayout,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Textarea,
} from "@cloudscape-design/components";
import { FormContent } from "@/helpers/form-helpers";
import { useFeedbackMessage } from "@/hooks/use-feedback-message";
import { useEffect, useState } from "react";
import { useHistory } from "@/hooks/use-history";

export function FeedbackPage() {
    const feedbackMessage = useFeedbackMessage();
    const history = useHistory();

    const [message, setMessage] = useState<string>("");
    const [subject, setSubject] = useState<string>("");

    async function submitForm() {
        feedbackMessage.send(subject, message);
    }

    function goBackOnComplete() {
        if (feedbackMessage.isSent === true) {
            history.back();
        }
    }

    useEffect(goBackOnComplete, [history, feedbackMessage.isSent]);

    return (
        <ContentLayout header={<FormHeader />}>
            <FormContent
                isLoading={feedbackMessage.isSent !== undefined}
                submitForm={submitForm}
                applyText="Send message"
            >
                <Container header={<Header variant="h2">Key settings</Header>}>
                    <SpaceBetween size="l">
                        <FormField
                            label="Subject"
                            description="Please feel free to leave it empty if it's challenging to choose."
                        >
                            <Input
                                value={subject}
                                onChange={({ detail }) =>
                                    setSubject(detail.value)
                                }
                            />
                        </FormField>
                        <FormField
                            label="Message"
                            description="Please describe your problem or suggestion."
                        >
                            <Textarea
                                value={message}
                                onChange={({ detail }) =>
                                    setMessage(detail.value)
                                }
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </FormContent>
        </ContentLayout>
    );
}

function FormHeader() {
    return (
        <Header variant="h1" description="We always appreciate your feedback.">
            Leave feedback
        </Header>
    );
}
