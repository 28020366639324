import {
    Container,
    ContentLayout,
    Header,
    Wizard,
} from "@cloudscape-design/components";
import {
    AddressElement,
    CardElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { useApi } from "@/context/api-provider";
import { useState } from "react";
import { useBillingSetup } from "@/hooks/use-billing-setup";
import { useNavigate } from "react-router-dom";

export function SetupPaymentPage() {
    const api = useApi();

    if (api === undefined) {
        return null;
    }

    return (
        <Elements stripe={api.stripe}>
            <ContentLayout header={<FormHeader />}>
                <StripeForm />
            </ContentLayout>
        </Elements>
    );
}

function StripeForm() {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const billingSetup = useBillingSetup();

    const [errorText, setErrorText] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function tryConfirmCard() {
        if (billingSetup.secret === undefined) {
            return;
        }

        const cardElement = elements?.getElement(CardElement);

        if (!stripe || !cardElement) {
            return;
        }

        setErrorText(undefined);
        setIsLoading(true);

        const setupResult = await stripe.confirmCardSetup(billingSetup.secret, {
            payment_method: {
                card: cardElement,
            },

            return_url: `${window.location.protocol}//${window.location.hostname}/`,
        });

        if (setupResult.error) {
            setErrorText(setupResult.error.message);
        } else if (setupResult.setupIntent?.status === "succeeded") {
            navigate("/");
        }

        setIsLoading(false);
    }

    async function trySetupAddress() {
        if (billingSetup.secret) {
            return;
        }

        const addressElement = elements?.getElement(AddressElement);

        if (!stripe || !addressElement || !elements) {
            return;
        }

        const checkResult = await elements.submit();

        if (checkResult.error) {
            return;
        }

        const addressValue = await addressElement.getValue();
        const address = addressValue.value.address;

        await billingSetup.setup({
            city: address.city,
            country: address.country,
            line1: address.line1,
            line2: address.line2,
            postal_code: address.postal_code,
            state: address.state,
            name: addressValue.value.name,
        });
    }

    async function advanceStep(stepIndex?: number) {
        if (stepIndex === 0 && billingSetup.secret) {
            billingSetup.reset();
        } else {
            await trySetupAddress();
            await tryConfirmCard();
        }
    }

    return (
        <Wizard
            onSubmit={() => advanceStep()}
            onNavigate={({ detail }) => advanceStep(detail.requestedStepIndex)}
            i18nStrings={{
                stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                navigationAriaLabel: "Steps",
                cancelButton: "Cancel",
                nextButton: "Next",
                previousButton: "Previous",
                submitButton: "Complete",
            }}
            activeStepIndex={billingSetup.secret ? 1 : 0}
            isLoadingNextStep={billingSetup.isLoading || isLoading}
            steps={[
                {
                    title: "Billing address",
                    errorText: billingSetup.errorText,
                    content: (
                        <AddressElement
                            options={{
                                mode: "billing",
                                autocomplete: {
                                    mode: "google_maps_api",
                                    apiKey: "AIzaSyDNqv23k_3aSYpelFqEs0dGDvsJo47pmE0",
                                },
                            }}
                        />
                    ),
                },
                {
                    title: "Card details",
                    errorText: errorText,
                    content: (
                        <Container
                            header={<Header variant="h2">Card details</Header>}
                        >
                            <CardElement options={{ hidePostalCode: true }} />
                        </Container>
                    ),
                },
            ]}
        />
    );
}

function FormHeader() {
    return (
        <Header variant="h1" description="We need it to make invoces properly">
            Setup payment information
        </Header>
    );
}
