import {
    Box,
    Button,
    CollectionPreferences,
    ContentLayout,
    Header,
    Pagination,
    SpaceBetween,
    StatusIndicator,
    Table,
    TextFilter,
} from "@cloudscape-design/components";
import { QueueListItem, useQueueSearch } from "@/hooks/use-queue-search";
import { ReactNode, useState } from "react";
import numeral from "numeral";
import {
    getHeaderCounterText,
    getMatchesCountText,
} from "@/helpers/search-helpers";
import { useNavigate } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import moment from "moment/moment";

const columnDefinitions = [
    {
        id: "name",
        cell: (row: QueueListItem) => row.name,
        header: "Name",
        isRowHeader: true,
        sortingField: "name",
    },
    {
        id: "size",
        header: "Size",
        cell: (row: QueueListItem) => numeral(row.bytes).format("0 ib"),
        sortingField: "bytes",
    },
    {
        id: "lines",
        header: "Lines",
        cell: (row: QueueListItem) =>
            numeral(row.lines).format("0,0").replace(/,/g, " "),
        sortingField: "lines",
    },
    {
        id: "creationTime",
        header: "Created At",
        cell: (row: QueueListItem) =>
            moment(Date.parse(row.creationTime)).calendar(),
        sortingField: "creationTime",
    },
    {
        id: "updateTime",
        header: "Updated At",
        cell: (row: QueueListItem) =>
            moment(Date.parse(row.updateTime)).calendar(),
        sortingField: "updateTime",
    },
    {
        id: "status",
        header: "Status",
        cell: (row: QueueListItem) =>
            row.isDeleted ? (
                <StatusIndicator type="pending">Deleted</StatusIndicator>
            ) : (
                <StatusIndicator type="info">OK</StatusIndicator>
            ),
        sortingField: "status",
    },
];

function EmptyState({ title, action }: { title: string; action: ReactNode }) {
    return (
        <Box textAlign="center" color="inherit">
            <Box
                variant="strong"
                textAlign="center"
                color="inherit"
                padding={"l"}
            >
                {title}
            </Box>
            <Box padding={"l"}>{action}</Box>
        </Box>
    );
}

export function QueueListPage() {
    return (
        <ContentLayout header={<PageHeader />}>
            <QueueList />
        </ContentLayout>
    );
}

function PageHeader() {
    return (
        <Header
            variant="h1"
            description="List of queues to manipulate manually."
        >
            Queues
        </Header>
    );
}

function QueueList() {
    const [preferences, setPreferences] = useState({
        pageSize: 10,
    });

    const queueSearch = useQueueSearch();
    const navigate = useNavigate();

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps,
    } = useCollection<QueueListItem>(queueSearch.result, {
        filtering: {
            empty: (
                <EmptyState
                    title="Create an access key and append lines to a queue to create a new one"
                    action={
                        <Button onClick={() => navigate("/create-key")}>
                            Create access key
                        </Button>
                    }
                />
            ),
            noMatch: (
                <EmptyState
                    title="No matches"
                    action={
                        <Button onClick={() => actions.setFiltering("")}>
                            Clear filter
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: preferences.pageSize },
        sorting: {},
        selection: {},
    });

    const { selectedItems } = collectionProps;

    function deleteSelectedQueue() {
        if (selectedItems) {
            queueSearch.deleteQueues(selectedItems.map((item) => item.name));
        }
    }

    function refreshQueueList() {
        queueSearch.loadQueues();
    }

    return (
        <Table
            {...collectionProps}
            selectionType="multi"
            loading={queueSearch.isLoading}
            isItemDisabled={(queue) => queue.isDeleted}
            header={
                <Header
                    counter={getHeaderCounterText(
                        queueSearch.result.length,
                        selectedItems?.length,
                    )}
                    actions={
                        <SpaceBetween size="xs" direction="horizontal">
                            <Button
                                loading={queueSearch.isLoading}
                                onClick={deleteSelectedQueue}
                                disabled={selectedItems?.length === 0}
                            >
                                Delete
                            </Button>
                            <Button
                                loading={queueSearch.isLoading}
                                onClick={refreshQueueList}
                                iconName="refresh"
                                variant="normal"
                            />
                        </SpaceBetween>
                    }
                >
                    Found queues
                </Header>
            }
            columnDefinitions={columnDefinitions}
            items={items}
            pagination={<Pagination {...paginationProps} />}
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringAriaLabel="Filter queues"
                />
            }
            preferences={
                <CollectionPreferences
                    title="Preferences"
                    confirmLabel="Confirm"
                    cancelLabel="Cancel"
                    preferences={preferences}
                    onConfirm={({ detail }) =>
                        setPreferences({ pageSize: detail.pageSize ?? 30 })
                    }
                    pageSizePreference={{
                        title: "Page size",
                        options: [
                            { value: 10, label: "10 Queues" },
                            { value: 30, label: "30 Queues" },
                            { value: 50, label: "50 Queues" },
                            { value: 100, label: "100 Queues" },
                        ],
                    }}
                />
            }
        />
    );
}
