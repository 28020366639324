import { useApi } from "@/context/api-provider";
import { useCallback, useState } from "react";
import { z } from "zod";

const CreateKeyResponseSchema = z.object({
    value: z.string().nonempty(),
    name: z.string(),
    tokenId: z.string(),
    creationTime: z.string().datetime({ offset: true }),
});

export type AllowedOperation =
    | "delete_queue"
    | "list_queues"
    | "append_lines"
    | "read_lines";

export type CreateKeyRequest = {
    readonly operations: AllowedOperation[];
    readonly resources: string[];
    readonly name: string;
};

export type NewKeyData = {
    readonly id: string;
    readonly secret: string;
    readonly name: string;
};

export type KeyFactory = {
    readonly isLoading: boolean;
    readonly errorText?: string;
    readonly resultKey?: NewKeyData;

    createKey(request: CreateKeyRequest): void;
};

export function useKeyFactory(): KeyFactory {
    const api = useApi();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>();
    const [resultKey, setResultKey] = useState<NewKeyData>();

    async function createKeyImpl(request: CreateKeyRequest) {
        if (api === undefined) {
            setErrorText("Application is not initialized properly");

            return;
        }

        setErrorText(undefined);
        setIsLoading(true);

        const createResult = await api.post(
            "Keys/Create",
            request,
            CreateKeyResponseSchema,
        );

        if (createResult instanceof Error) {
            setErrorText(createResult.message);
        } else {
            setResultKey({
                secret: createResult.value,
                id: createResult.tokenId,
                name: createResult.name,
            });
        }

        setIsLoading(false);
    }

    const createKey = useCallback(createKeyImpl, [api]);

    return {
        isLoading,
        errorText,
        resultKey,
        createKey,
    };
}
