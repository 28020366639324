import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    ContentLayout,
    Grid,
    Header,
    Popover,
    StatusIndicator,
} from "@cloudscape-design/components";
import { Summary, useBillingSummary } from "@/hooks/use-billing-summary";
import numeral from "numeral";

export function HomePage() {
    const { summary, isLoading } = useBillingSummary();

    const footer =
        summary && summary.paymentSetupRequired ? (
            <SetupPaymentButton isLoading={isLoading} />
        ) : null;

    return (
        <ContentLayout header={<PageHeader />}>
            <Box padding={{ top: "xxxl", left: "xxxl", right: "xxxl" }}>
                <Container
                    header={<Header variant="h2">Billing summary</Header>}
                    footer={footer}
                >
                    <ColumnLayout columns={2} variant="text-grid">
                        <Box>
                            <Box variant="awsui-key-label">
                                Subscription state
                            </Box>
                            <SubscriptionState
                                summary={summary}
                                isLoading={isLoading}
                            />

                            <Box
                                variant="awsui-key-label"
                                padding={{ top: "m" }}
                            >
                                Total storage volume
                            </Box>
                            <TotalStorageVolume
                                summary={summary}
                                isLoading={isLoading}
                            />
                        </Box>

                        <Box>
                            <Box variant="awsui-key-label">
                                Month to date balance
                            </Box>
                            <UsageUsd summary={summary} isLoading={isLoading} />

                            <Box
                                variant="awsui-key-label"
                                padding={{ top: "m" }}
                            >
                                Total month's forecast
                            </Box>
                            <UsageForecastUsd
                                summary={summary}
                                isLoading={isLoading}
                            />
                        </Box>
                    </ColumnLayout>
                </Container>
            </Box>
        </ContentLayout>
    );
}

function PageHeader() {
    const navigate = useNavigate();

    function handleStartClick() {
        navigate("/create-key");
    }

    return (
        <Box padding={{ top: "xxxl", left: "xxxl", right: "xxxl" }}>
            <Grid
                gridDefinition={[
                    { colspan: { xs: 8, xxs: 12 } },
                    { colspan: { xs: 4, xxs: 12 } },
                ]}
            >
                <Header
                    variant="h1"
                    description="LinesQueue is usable for ETL systems, data analytics, and machine learning. You can store your records in durable queues that allow you to replay and re-transform the data anytime."
                >
                    LinesQueue
                    <br />
                    <small>Streaming service for valuable data</small>
                </Header>

                {/* If you don't know where to go */}
                <Container header={<Header variant="h2">Get started</Header>}>
                    <div>
                        Learn how to use LinesQueue by creating an access key,
                        sending lines to the queue, and receiving it back.
                    </div>
                    <br />

                    <Button variant="primary" onClick={handleStartClick}>
                        Start now
                    </Button>
                </Container>
            </Grid>
        </Box>
    );
}

function UsageForecastUsd(props: { summary?: Summary; isLoading: boolean }) {
    if (props.summary === undefined || props.isLoading) {
        return <StatusIndicator type="loading">Loading...</StatusIndicator>;
    }

    const popoverText =
        numeral(props.summary.totalBytes).format("0 ib") +
        " for " +
        props.summary.remainingHours +
        "h";

    return (
        <Box color="text-body-secondary">
            <Popover
                content={popoverText}
                size="small"
                dismissButton={false}
                position="bottom"
            >
                USD {numeral(props.summary.usageForecastUsd).format("0.00")}
            </Popover>
        </Box>
    );
}

function UsageUsd(props: { summary?: Summary; isLoading: boolean }) {
    if (props.summary === undefined || props.isLoading) {
        return <StatusIndicator type="loading">Loading...</StatusIndicator>;
    }

    const popoverText = props.summary.usageGigabyteHours + " GB-hours used";

    return (
        <Box color="text-body-secondary">
            <Popover
                content={popoverText}
                size={"small"}
                dismissButton={false}
                position="bottom"
            >
                USD {numeral(props.summary.usageUsd).format("0.00")}
            </Popover>
        </Box>
    );
}

function TotalStorageVolume(props: { summary?: Summary; isLoading: boolean }) {
    if (props.summary === undefined || props.isLoading) {
        return <StatusIndicator type="loading">Loading...</StatusIndicator>;
    }

    return <>{numeral(props.summary.totalBytes).format("0 ib")}</>;
}

function SubscriptionState(props: { summary?: Summary; isLoading: boolean }) {
    if (props.summary === undefined || props.isLoading) {
        return <StatusIndicator type="loading">Loading...</StatusIndicator>;
    }

    return !props.summary.paymentSetupRequired &&
        props.summary.isSubscriptionActive ? (
        <StatusIndicator type="success">Active</StatusIndicator>
    ) : props.summary.isSubscriptionActive ? (
        <StatusIndicator type="info">Free Tier</StatusIndicator>
    ) : (
        <StatusIndicator type="warning">Payment Issues</StatusIndicator>
    );
}

function SetupPaymentButton(props: { isLoading: boolean }) {
    const navigate = useNavigate();

    return (
        <Button
            variant="primary"
            loading={props.isLoading}
            disabled={props.isLoading}
            onClick={() => navigate("/setup-payment")}
        >
            Setup subscription
        </Button>
    );
}
